import React from 'react'
import {
    AAL,
    AAPL,
    ABNB,
    ADS,
    AIG,
    AMZN,
    AUDCAD,
    AUDCHF,
    AUDBASKET,
    AUDJPY,
    AUDNZD,
    AUDUSD,
    AUS200,
    BA,
    BABA,
    BAC,
    BAY,
    BCHUSD,
    BIIB,
    BMW,
    BOOM1000,
    BOOM500,
    BTCUSD,
    C,
    CADCHF,
    CADJPY,
    CHFJPY,
    CLBRENT,
    CRASH1000,
    CRASH500,
    CRM,
    CSCO,
    DAL,
    DBK,
    DIS,
    DSHUSD,
    EBAY,
    EOSUSD,
    ESP35,
    EUR50,
    EURCAD,
    EURCHF,
    EURGBP,
    EURBASKET,
    EURJPY,
    EURNZD,
    EURUSD,
    FB,
    FDX,
    FRA40,
    GBPAUD,
    GBPCAD,
    GBPCHF,
    GBPBASKET,
    GBPJPY,
    GBPNZD,
    GBPUSD,
    GM,
    GOLDUSD,
    GOLDBASKET,
    GOOGL,
    GS,
    HPQ,
    IBM,
    INDEX10,
    INDEX100,
    INDEX100S1,
    INDEX10S1,
    INDEX25,
    INDEX25S1,
    INDEX50,
    INDEX50S1,
    INDEX75,
    INDEX75S1,
    INTC,
    JNJ,
    JP225,
    JPM,
    KO,
    LTCUSD,
    MA,
    MCD,
    MRNA,
    MSFT,
    NED25,
    NFLX,
    NKE,
    NVDA,
    NZDCAD,
    NZDJPY,
    NZDUSD,
    OILUSD,
    PALLADIUMUSD,
    PFE,
    PG,
    PLATINUMUSD,
    PYPL,
    RANGEBREAK100,
    RANGEBREAK200,
    SILVERUSD,
    STEPINDICES,
    TEVA,
    TSLA,
    TWTR,
    UBER,
    UK100,
    US100,
    US30,
    US500,
    USDCAD,
    USDCHF,
    USDBASKET,
    USDJPY,
    USDZAR,
    V,
    WMT,
    XRPUSD,
    ZM,
} from 'components/elements/symbols.js'

export const optionItemDefault = {
    name: 'default',
    display_name: '',
    key: '14',
    icon: '',
    market: '',
}

export const optionLeverageDefault = {
    name: 'value',
    display_name: '',
    key: '14',
    icon: '',
    market: '',
}

export const leverageItemLists = [
    { name: 4000, display_name: 4000, key: '1', icon: '' },
    { name: 3500, display_name: 3500, key: '2', icon: '' },
    { name: 3000, display_name: 3000, key: '3', icon: '' },
    { name: 2500, display_name: 2500, key: '4', icon: '' },
    { name: 2000, display_name: 2000, key: '5', icon: '' },
    { name: 1500, display_name: 1500, key: '6', icon: '' },
    { name: 1000, display_name: 1000, key: '7', icon: '' },
    { name: 900, display_name: 900, key: '8', icon: '' },
    { name: 800, display_name: 800, key: '9', icon: '' },
    { name: 700, display_name: 700, key: '10', icon: '' },
    { name: 600, display_name: 600, key: '11', icon: '' },
    { name: 500, display_name: 500, key: '12', icon: '' },
    { name: 400, display_name: 400, key: '13', icon: '' },
    { name: 200, display_name: 200, key: '14', icon: '' },
    { name: 100, display_name: 100, key: '15', icon: '' },
    { name: 50, display_name: 50, key: '16', icon: '' },
    { name: 30, display_name: 30, key: '17', icon: '' },
    { name: 5, display_name: 5, key: '18', icon: '' },
]

export const syntheticItemLists = [
    {
        name: 'Boom 1000 Index',
        display_name: 'Boom 1000 Index',
        icon: <img src={BOOM1000} alt="boom 100" />,
        market: 'synthetic_indices',
    },
    {
        name: 'Boom 500 Index',
        display_name: 'Boom 500 Index',
        icon: <img src={BOOM500} alt="boom 500" />,
        market: 'synthetic_indices',
    },
    {
        name: 'Crash 1000 Index',
        display_name: 'Crash 1000 Index',
        icon: <img src={CRASH1000} alt="crash 1000" />,
        market: 'synthetic_indices',
    },
    {
        name: 'Crash 500 Index',
        display_name: 'Crash 500 Index',
        icon: <img src={CRASH500} alt="crash 500" />,
        market: 'synthetic_indices',
    },
    {
        name: 'Range Break 100 Index',
        display_name: 'Range Break 100 Index',
        icon: <img src={RANGEBREAK100} alt="" />,
        market: 'synthetic_indices',
    },
    {
        name: 'Range Break 200 Index',
        display_name: 'Range Break 200 Index',
        icon: <img src={RANGEBREAK200} alt="range break 200" />,
        market: 'synthetic_indices',
    },
    {
        name: 'Step Index',
        display_name: 'Step Index',
        icon: <img src={STEPINDICES} alt="step index" />,
        market: 'synthetic_indices',
    },
    {
        name: 'Volatility 10 (1s) Index',
        display_name: 'Volatility 10 (1s) Index',
        icon: <img src={INDEX10S1} alt="vol 10 1s" />,
        market: 'synthetic_indices',
    },
    {
        name: 'Volatility 10 Index',
        display_name: 'Volatility 10 Index',
        icon: <img src={INDEX10} alt="vol 10" />,
        market: 'synthetic_indices',
    },
    {
        name: 'Volatility 25 (1s) Index',
        display_name: 'Volatility 25 (1s) Index',
        icon: <img src={INDEX25S1} alt="vol 25 1s" />,
        market: 'synthetic_indices',
    },
    {
        name: 'Volatility 25 Index',
        display_name: 'Volatility 25 Index',
        icon: <img src={INDEX25} alt="" />,
        market: 'synthetic_indices',
    },
    {
        name: 'Volatility 50 (1s) Index',
        display_name: 'Volatility 50 (1s) Index',
        icon: <img src={INDEX50S1} alt="vol 50 1s" />,
        market: 'synthetic_indices',
    },
    {
        name: 'Volatility 50 Index',
        display_name: 'Volatility 50 Index',
        icon: <img src={INDEX50} alt="vol 50" />,
        market: 'synthetic_indices',
    },
    {
        name: 'Volatility 75 (1s) Index',
        display_name: 'Volatility 75 (1s) Index',
        icon: <img src={INDEX75S1} alt="vol 75 1s" />,
        market: 'synthetic_indices',
    },
    {
        name: 'Volatility 75 Index',
        display_name: 'Volatility 75 Index',
        icon: <img src={INDEX75} alt="vol 75" />,
        market: 'synthetic_indices',
    },
    {
        name: 'Volatility 100 (1s) Index',
        display_name: 'Volatility 100 (1s) Index',
        icon: <img src={INDEX100S1} alt="vol 100 1s" />,
        market: 'synthetic_indices',
    },
    {
        name: 'Volatility 100 Index',
        display_name: 'Volatility 100 Index',
        icon: <img src={INDEX100} alt="vol 100" />,
        market: 'synthetic_indices',
    },
]

export const financialItemLists = [
    {
        name: 'AAL',
        display_name: 'American Airlines Group Inc',
        icon: <img src={AAL} alt="american airlines group inc" />,
        market: 'other',
    },
    {
        name: 'AAPL',
        display_name: 'Apple',
        icon: <img src={AAPL} alt="apple" />,
        market: 'other',
    },
    {
        name: 'ABNB',
        display_name: 'Airbnb Inc',
        icon: <img src={ABNB} alt="airbnb" />,
        market: 'other',
    },
    {
        name: 'ADS',
        display_name: 'Adidas Salomon',
        icon: <img src={ADS} alt="adidas salomon" />,
        market: 'other',
    },
    {
        name: 'AIG',
        display_name: 'American International Group',
        icon: <img src={AIG} alt="american international group" />,
        market: 'other',
    },
    {
        name: 'AMZN',
        display_name: 'Amazon.com Inc',
        icon: <img src={AMZN} alt="amazon.com inc" />,
        market: 'other',
    },
    {
        name: 'BA',
        display_name: 'Boeing',
        icon: <img src={BA} alt="boeing" />,
        market: 'other',
    },
    {
        name: 'BABA',
        display_name: 'Alibaba Group Holding Ltd',
        icon: <img src={BABA} alt="alibaba group holding ltd" />,
        market: 'other',
    },
    {
        name: 'BAC',
        display_name: 'Bank of America',
        icon: <img src={BAC} alt="bank of america" />,
        market: 'other',
    },
    {
        name: 'BAY',
        display_name: 'Bayer AG',
        icon: <img src={BAY} alt="bayer ag" />,
        market: 'other',
    },
    {
        name: 'BIIB',
        display_name: 'Biogen Inc.',
        icon: <img src={BIIB} alt="biogen inc." />,
        market: 'other',
    },
    {
        name: 'BMW',
        display_name: 'BMW',
        icon: <img src={BMW} alt="bmw" />,
        market: 'other',
    },
    {
        name: 'C',
        display_name: 'Citigroup',
        icon: <img src={C} alt="citigroup" />,
        market: 'other',
    },
    {
        name: 'CRM',
        display_name: 'Salesforce.com Inc',
        icon: <img src={CRM} alt="salesforce.com inc" />,
        market: 'other',
    },
    {
        name: 'CSCO',
        display_name: 'Cisco Systems',
        icon: <img src={CSCO} alt="cisco systems" />,
        market: 'other',
    },
    {
        name: 'DAL',
        display_name: 'Delta Air Lines',
        icon: <img src={DAL} alt="delta air lines" />,
        market: 'other',
    },
    {
        name: 'DBK',
        display_name: 'Deutsche Bank AG',
        icon: <img src={DBK} alt="deutsche bank ag" />,
        market: 'other',
    },
    {
        name: 'DIS',
        display_name: 'Walt Disney Company',
        icon: <img src={DIS} alt="walt disney company" />,
        market: 'other',
    },
    {
        name: 'EBAY',
        display_name: 'eBay Inc',
        icon: <img src={EBAY} alt="ebay inc" />,
        market: 'other',
    },
    {
        name: 'FB',
        display_name: 'Facebook Inc',
        icon: <img src={FB} alt="facebook inc" />,
        market: 'other',
    },
    {
        name: 'FDX',
        display_name: 'Fedex',
        icon: <img src={FDX} alt="fedex" />,
        market: 'other',
    },
    {
        name: 'GM',
        display_name: 'General Motors Company',
        icon: <img src={GM} alt="general motors company" />,
        market: 'other',
    },
    {
        name: 'GOOGL',
        display_name: 'Alphabet Inc Class C',
        icon: <img src={GOOGL} alt="alphabet inc class c" />,
        market: 'other',
    },
    {
        name: 'GS',
        display_name: 'Goldman Sachs Group',
        icon: <img src={GS} alt="goldman sachs group" />,
        market: 'other',
    },
    {
        name: 'HPQ',
        display_name: 'HP Inc',
        icon: <img src={HPQ} alt="hp inc" />,
        market: 'other',
    },
    {
        name: 'IBM',
        display_name: 'IBM',
        icon: <img src={IBM} alt="ibm" />,
        market: 'other',
    },
    {
        name: 'INTC',
        display_name: 'Intel',
        icon: <img src={INTC} alt="intel" />,
        market: 'other',
    },
    {
        name: 'JNJ',
        display_name: 'Jonhson & Johnson',
        icon: <img src={JNJ} alt="jonhson and johnson" />,
        market: 'other',
    },
    {
        name: 'JPM',
        display_name: 'JP Morgan Chase',
        icon: <img src={JPM} alt="jp morgan chase" />,
        market: 'other',
    },
    {
        name: 'KO',
        display_name: 'Coca-Cola',
        icon: <img src={KO} alt="coca-cola" />,
        market: 'other',
    },
    {
        name: 'MA',
        display_name: 'Mastercard Inc',
        icon: <img src={MA} alt="mastercard inc" />,
        market: 'other',
    },
    {
        name: 'MCD',
        display_name: 'McDonalds',
        icon: <img src={MCD} alt="McDonalds" />,
        market: 'other',
    },
    {
        name: 'MRNA',
        display_name: 'Moderna Inc',
        icon: <img src={MRNA} alt="moderna inc" />,
        market: 'other',
    },
    {
        name: 'MSFT',
        display_name: 'Microsoft Corporation',
        icon: <img src={MSFT} alt="microsoft corporation" />,
        market: 'other',
    },
    {
        name: 'NFLX',
        display_name: 'Netflix Inc',
        icon: <img src={NFLX} alt="netflix inc" />,
        market: 'other',
    },
    {
        name: 'NKE',
        display_name: 'Nike',
        icon: <img src={NKE} alt="nike" />,
        market: 'other',
    },
    {
        name: 'NVDA',
        display_name: 'NVIDIA Corp',
        icon: <img src={NVDA} alt="nvidia corp" />,
        market: 'other',
    },
    {
        name: 'PFE',
        display_name: 'Pfizer',
        icon: <img src={PFE} alt="pfizer" />,
        market: 'other',
    },
    {
        name: 'PG',
        display_name: 'Procter & Gamble',
        icon: <img src={PG} alt="procter and gamble" />,
        market: 'other',
    },
    {
        name: 'PYPL',
        display_name: 'Paypal',
        icon: <img src={PYPL} alt="paypal" />,
        market: 'other',
    },
    {
        name: 'TEVA',
        display_name: 'Teva Pharmaceutical Inds',
        icon: <img src={TEVA} alt="teva pharmaceutical inds" />,
        market: 'other',
    },
    {
        name: 'TSLA',
        display_name: 'Tesla Inc',
        icon: <img src={TSLA} alt="tesla inc" />,
        market: 'other',
    },
    {
        name: 'TWTR',
        display_name: 'Twitter',
        icon: <img src={TWTR} alt="twitter" />,
        market: 'other',
    },
    {
        name: 'UBER',
        display_name: 'Uber Technologies Inc',
        icon: <img src={UBER} alt="uber technologies inc" />,
        market: 'other',
    },
    {
        name: 'V',
        display_name: 'Visa',
        icon: <img src={V} alt="visa" />,
        market: 'other',
    },
    {
        name: 'WMT',
        display_name: 'Wal-Mart Stores Inc',
        icon: <img src={WMT} alt="wal-mart stores inc" />,
        market: 'other',
    },
    {
        name: 'ZM',
        display_name: 'Zoom Video Communications Inc',
        icon: <img src={ZM} alt="zoom video communications inc" />,
        market: 'other',
    },
    {
        name: 'AUS200',
        display_name: 'Australia 200',
        icon: <img src={AUS200} alt="australia 200" />,
        market: 'other',
    },
    {
        name: 'ESP35',
        display_name: 'Spain 35',
        icon: <img src={ESP35} alt="spain 35" />,
        market: 'other',
    },
    {
        name: 'EUR50',
        display_name: 'Europe 50',
        icon: <img src={EUR50} alt="europe 50" />,
        market: 'other',
    },
    {
        name: 'FRA40',
        display_name: 'France 40',
        icon: <img src={FRA40} alt="france 40" />,
        market: 'other',
    },
    {
        name: 'UK100',
        display_name: 'UK 100',
        icon: <img src={UK100} alt="uk 100" />,
        market: 'other',
    },
    {
        name: 'JP225',
        display_name: 'Japan 225',
        icon: <img src={JP225} alt="japan 225" />,
        market: 'other',
    },
    {
        name: 'NED25',
        display_name: 'Netherland 25',
        icon: <img src={NED25} alt="netherland 25" />,
        market: 'other',
    },
    {
        name: 'US500',
        display_name: 'US 500',
        icon: <img src={US500} alt="us 500" />,
        market: 'other',
    },
    {
        name: 'US100',
        display_name: 'US 100',
        icon: <img src={US100} alt="us 100" />,
        market: 'other',
    },
    {
        name: 'US30',
        display_name: 'Wall Street 30',
        icon: <img src={US30} alt="wall street 30" />,
        market: 'other',
    },
    {
        name: 'CL_BRENT',
        display_name: 'CL_BRENT',
        icon: <img src={CLBRENT} alt="cl brent" />,
        market: 'other',
    },
    {
        name: 'BCHUSD',
        display_name: 'BCH/USD',
        icon: <img src={BCHUSD} alt="bch usd" />,
        market: 'crypto',
    },
    {
        name: 'BTCUSD',
        display_name: 'BTC/USD',
        icon: <img src={BTCUSD} alt="btc usd" />,
        market: 'crypto',
    },
    {
        name: 'DSHUSD',
        display_name: 'DSH/USD',
        icon: <img src={DSHUSD} alt="dsh usd" />,
        market: 'crypto',
    },
    {
        name: 'EOSUSD',
        display_name: 'EOS/USD',
        icon: <img src={EOSUSD} alt="eos usd" />,
        market: 'crypto',
    },
    {
        name: 'LTCUSD',
        display_name: 'LTC/USD',
        icon: <img src={LTCUSD} alt="ltc usd" />,
        market: 'crypto',
    },
    {
        name: 'XRPUSD',
        display_name: 'XRP/USD',
        icon: <img src={XRPUSD} alt="xrp usd" />,
        market: 'crypto',
    },
    {
        name: 'AUDCAD',
        display_name: 'AUD/CAD',
        icon: <img src={AUDCAD} alt="aud cad" />,
        market: 'forex',
    },
    {
        name: 'AUDCHF',
        display_name: 'AUD/CHF',
        icon: <img src={AUDCHF} alt="aud chf" />,
        market: 'forex',
    },
    {
        name: 'AUDJPY',
        display_name: 'AUD/JPY',
        icon: <img src={AUDJPY} alt="aud jpy" />,
        market: 'forex',
    },
    {
        name: 'AUDNZD',
        display_name: 'AUD/NZD',
        icon: <img src={AUDNZD} alt="aud nzd" />,
        market: 'forex',
    },
    {
        name: 'AUDUSD',
        display_name: 'AUD/USD',
        icon: <img src={AUDUSD} alt="aud usd" />,
        market: 'forex',
    },
    {
        name: 'EURCAD',
        display_name: 'EUR/CAD',
        icon: <img src={EURCAD} alt="eur cad" />,
        market: 'forex',
    },
    {
        name: 'EURCHF',
        display_name: 'EUR/CHF',
        icon: <img src={EURCHF} alt="eur chf" />,
        market: 'forex',
    },
    {
        name: 'EURGBP',
        display_name: 'EUR/GBP',
        icon: <img src={EURGBP} alt="eur gbp" />,
        market: 'forex',
    },
    {
        name: 'EURJPY',
        display_name: 'EUR/JPY',
        icon: <img src={EURJPY} alt="eur jpy" />,
        market: 'forex',
    },
    {
        name: 'EURNZD',
        display_name: 'EUR/NZD',
        icon: <img src={EURNZD} alt="eur nzd" />,
        market: 'forex',
    },
    {
        name: 'EURUSD',
        display_name: 'EUR/USD',
        icon: <img src={EURUSD} alt="eur usd" />,
        market: 'forex',
    },
    {
        name: 'GBPCHF',
        display_name: 'GBP/CHF',
        icon: <img src={GBPCHF} alt="gbp chf" />,
        market: 'forex',
    },
    {
        name: 'GBPJPY',
        display_name: 'GBP/JPY',
        icon: <img src={GBPJPY} alt="gbp jpy" />,
        market: 'forex',
    },
    {
        name: 'GBPUSD',
        display_name: 'GBP/USD',
        icon: <img src={GBPUSD} alt="gbp usd" />,
        market: 'forex',
    },
    {
        name: 'NZDUSD',
        display_name: 'NZD/USD',
        icon: <img src={NZDUSD} alt="nzd usd" />,
        market: 'forex',
    },
    {
        name: 'USDCAD',
        display_name: 'USD/CAD',
        icon: <img src={USDCAD} alt="usd cad" />,
        market: 'forex',
    },
    {
        name: 'USDCHF',
        display_name: 'USD/CHF',
        icon: <img src={USDCHF} alt="usd chf" />,
        market: 'forex',
    },
    {
        name: 'USDJPY',
        display_name: 'USD/JPY',
        icon: <img src={USDJPY} alt="usd jpy" />,
        market: 'forex',
    },
    {
        name: 'CADCHF',
        display_name: 'CAD/CHF',
        icon: <img src={CADCHF} alt="cad chf" />,
        market: 'forex',
    },
    {
        name: 'CADJPY',
        display_name: 'CAD/JPY',
        icon: <img src={CADJPY} alt="cad jpy" />,
        market: 'forex',
    },
    {
        name: 'GBPAUD',
        display_name: 'GBP/AUD',
        icon: <img src={GBPAUD} alt="gbp aud" />,
        market: 'forex',
    },
    {
        name: 'GBPCAD',
        display_name: 'GBP/CAD',
        icon: <img src={GBPCAD} alt="gbp cad" />,
        market: 'forex',
    },
    {
        name: 'GBPNZD',
        display_name: 'GBP/NZD',
        icon: <img src={GBPNZD} alt="gbp nzd" />,
        market: 'forex',
    },
    {
        name: 'NZDCAD',
        display_name: 'NZD/CAD',
        icon: <img src={NZDCAD} alt="nzd cad" />,
        market: 'forex',
    },
    {
        name: 'NZDJPY',
        display_name: 'NZD/JPY',
        icon: <img src={NZDJPY} alt="nzd jpy" />,
        market: 'forex',
    },
    {
        name: 'USDZAR',
        display_name: 'USD/ZAR',
        icon: <img src={USDZAR} alt="usd zar" />,
        market: 'forex',
    },
    {
        name: 'CHFJPY',
        display_name: 'CHF/JPY',
        icon: <img src={CHFJPY} alt="chf  jpy" />,
        market: 'forex',
    },
    {
        name: 'WTI_OIL',
        display_name: 'Oil/USD',
        icon: <img src={OILUSD} alt="oil usd" />,
        market: 'commodities',
    },
    {
        name: 'XAUUSD',
        display_name: 'Gold/USD',
        icon: <img src={GOLDUSD} alt="gold usd" />,
        market: 'commodities',
    },
    {
        name: 'XAGUSD',
        display_name: 'Silver/USD',
        icon: <img src={SILVERUSD} alt="silver usd" />,
        market: 'commodities',
    },
    {
        name: 'XPDUSD',
        display_name: 'Palladium/USD',
        icon: <img src={PALLADIUMUSD} alt="palladium usd" />,
        market: 'commodities',
    },
    {
        name: 'XPTUSD',
        display_name: 'Platinum/USD',
        icon: <img src={PLATINUMUSD} alt="platinum usd" />,
        market: 'commodities',
    },
    {
        name: 'AUDBASKET',
        display_name: 'AUD Basket',
        icon: <img src={AUDBASKET} alt="aud basket" />,
        market: 'basket_indices',
    },
    {
        name: 'GBPBASKET',
        display_name: 'GBP Basket',
        icon: <img src={GBPBASKET} alt="gbp basket" />,
        market: 'basket_indices',
    },
    {
        name: 'EURBASKET',
        display_name: 'EUR Basket',
        icon: <img src={EURBASKET} alt="eur basket" />,
        market: 'basket_indices',
    },
    {
        name: 'USDBASKET',
        display_name: 'USD Basket',
        icon: <img src={USDBASKET} alt="usd basket" />,
        market: 'basket_indices',
    },
    {
        name: 'GOLDBASKET',
        display_name: 'Gold Basket',
        icon: <img src={GOLDBASKET} alt="gold basket" />,
        market: 'basket_indices',
    },
]
